
import flatPickr from 'vue-flatpickr-component';
import modals from "../../mixins/modals";
import optionButtonTooltip from "../../mixins/option-button-tooltip";
import ContactName from "../contacts/contact-name.vue";
import TableActions from "../table-actions.vue";
import callLog, {INVESTOR_LOG, LOG_TYPES, MANAGEMENT_LOG, SOURCES} from "../../mixins/call-log";
import fileDownload from "../../mixins/file-download";
import QuintableSearchInput from "../helpers/quintable-search-input.vue";
import ConfirmModal from "../confirm-modal.vue";
import TableFilters from "../table-filters.vue";
import EditCallLogModal from "./edit-call-log-modal.vue";
import 'flatpickr/dist/flatpickr.css';
import CallLogBulkOperationModal from "./call-log-bulk-operation-modal.vue";

export default {
    name: "CallLogTable",
    components: {
        EditCallLogModal,
        TableFilters,
        ConfirmModal,
        CallLogBulkOperationModal,
        QuintableSearchInput,
        TableActions,
        ContactName,
        flatPickr
    },
    mixins: [modals, fileDownload, optionButtonTooltip, callLog],
    props: {
        company: {
            type: Object,
            default: null,
        },
        contact: {
            type: Object,
            default: null,
        },
        initialActiveType: {
            type: Number,
            default: INVESTOR_LOG,
        }
    },
    data() {
        return {
            modal: null,
            tableUpdate: null,
            callLogToEdit: null,
            callLogNoteIdToDelete: null,
            SOURCES,
            LOG_TYPES,
            INVESTOR_LOG,
            MANAGEMENT_LOG,
            filterOptions: {
                types: [],
            },
            // Filters START
            selectedAnalysts: [],
            selectedType: null,
            selectedSource: null,
            selectedInvestorCompanies: [],
            selectedInvestorContacts: [],
            selectedCompanies: [],
            dates: null,
            // Filters END
            dateConfig: {
                mode: 'range',
                altInput: true,
                altFormat: "m/d/Y",
                dateFormat: 'Y-m-d',
            },
            callLogModalKey: "callLogModalKey",
            sortOrder: [{
                headline: "Quarter",
                index: 1,
                asc: false,
            }],
            companiesMissing: false,
            selectedRows: [],
            preSelectedRows: [],
            preSelectedRowIds: [],
            ajaxRows: null,
            pageChanged: false,
            bulkModalKey: 'bulkModalKey',
            activeType: INVESTOR_LOG,
        }
    },
    computed: {
        filters() {
            let lowerDate = null;
            let upperDate = null;
            if (this.dates) {
                [lowerDate, upperDate = lowerDate] = this.dates.split(" to ");
            }

            return {
                analysts: this.selectedAnalysts.map(u => u.id),
                type: this.selectedType,
                source: this.selectedSource,
                investorCompanies: this.selectedInvestorCompanies.map(x => x.id),
                investorContacts: this.selectedInvestorContacts.map(x => x.id),
                companies: this.selectedCompanies.map(x => x.id),
                lowerDate,
                upperDate,
                forCompanyId: this.company?.id,
                forContactId: this.contact?.id,
                companiesMissing: this.companiesMissing,
                callLogType: this.activeType,
            }
        },
        config() {
            return {
                columns: [
                    {
                        headline: "Analysts",
                    },
                    {
                        headline: "Quarter",
                        sort: true,
                    },
                    {
                        headline: "Month",
                        breakpoint: "all",
                    },
                    {
                        headline: "Date",
                        breakpoint: "all",
                        sort: true,
                    },
                    {
                        headline: "Type of Interaction",
                        breakpoint: "md",
                    },
                    {
                        headline: "Source of Contact",
                        breakpoint: "md",
                        hidden: this.activeType === MANAGEMENT_LOG,
                    },
                    {
                        headline: this.activeType === INVESTOR_LOG ? "Investors" : "Companies",
                    },
                    {
                        headline: (this.activeType === INVESTOR_LOG ? "Investor" : "Company") + " Contacts",
                        breakpoint: "sm",
                    },
                    {
                        headline: "Companies",
                        breakpoint: "sm",
                        hidden: this.activeType === MANAGEMENT_LOG,
                    },
                    {
                        headline: "Notes",
                        breakpoint: "all",
                    },
                    {
                        headline: "Actions",
                        hideHeadlineBreakpoint: "all",
                        align: "end"
                    }
                ],
                ajaxUrl: '/api/call_log_notes/list',
                pagination: 25,
                search: true,
                select: true,
                selectPosition: "pre",
                prettySelect: true,
            }
        },
        companyForNewEntries() {
            if (this.contact) {
                return this.contact.company;
            } else {
                return this.company;
            }
        },
        selectedRowIds() {
            return this.selectedRows.map(r => r.cln_id);
        }
    },
    watch: {
        filters() {
            this.pageChanged = true;
        },
        selectedRows(rows) {
            if (!this.pageChanged && this.ajaxRows) {

                for (let i = 0; i < rows.length; i++) {
                    if (!this.preSelectedRowIds.includes(rows[i].cln_id)) {
                        this.preSelectedRowIds.push(rows[i].cln_id);
                    }
                }

                for (let j = 0; j < this.ajaxRows.length; j++) {
                    const id = this.ajaxRows[j].cln_id;

                    const index = this.preSelectedRowIds.indexOf(id);

                    if (!rows.map(r => r.cln_id).includes(id) && index !== -1) {

                        this.preSelectedRowIds.splice(index, 1);
                    }
                }
            }
        },
        activeType() {
            this.resetFilters();
        },
    },
    mounted() {
        this.activeType = this.initialActiveType;

        this.loadFilterOptions();

        if (
            this.isUserWithRoleResearchAnalyst &&
            !this.isUserWithRoleBanker &&
            !this.isUserWithRoleCorporateAccess
        ) {
            this.selectedAnalysts = [{
                id: this.userInfo.id,
                name: this.userInfo.name,
            }];
        }
    },
    methods: {
        loadFilterOptions() {
            this.$axios.get("/api/call_logs/filter_options")
                .then(response => {
                    this.filterOptions = response.data;
                });
        },
        addLog() {
            this.callLogToEdit = null;

            let url = '/api/call_logs/template/' + this.activeType;
            if (this.contact) {
                url += `?contact=${this.contact.id}&company=${this.contact?.company?.id}`;
            } else if (this.company) {
                url += `?company=${this.company.id}`;
            }

            this.$axios.get(url)
                .then(async (response) => {
                    this.callLogToEdit = response.data;
                    await this.$nextTick();
                    this.modal = this.openModal(this.$refs.callLogModal);
                });
        },
        onUpdated() {
            this.tableUpdate = new Date();
            this.closeCallLogModal();
        },
        closeCallLogModal() {
            if (this.modal) {
                this.closeModal(this.modal);
                this.modal = null;
            }
        },
        downloadCallLog() {
            const query = {
                search: this.$refs.callLogTable.query,
                filters: this.filters,
            };
            this.saveQueryAndExport(query, '/api/call_log_notes/excel');
        },
        deleteCallLogNote(id) {
            console.log("Delete", id);
            this.callLogNoteIdToDelete = id;
            this.modal = this.openModal(this.$refs.deleteCallLogNoteModal);
        },
        doDeleteCallLogNote(id) {
            this.$axios.delete(`/api/call_log_notes/${id}`)
                .then(() => {
                    const toastId = this.generateUUID();
                    this.addToast({
                        type: "success",
                        title: "Note deleted",
                        message: "The call log note has been deleted successfully.",
                        id: toastId,
                    });
                    this.$nextTick(() => {
                        this.toggleToast(toastId);
                    })
                })
                .finally(() => {
                    this.callLogNoteIdToDelete = null;
                    this.modal = null;
                    this.tableUpdate = new Date();
                });
        },
        doDeleteCallLog(id) {
            this.$axios.delete(`/api/call_logs/${id}`)
                .then(() => {
                    const toastId = this.generateUUID();
                    this.addToast({
                        type: "success",
                        title: "Call Log deleted",
                        message: "The call log has been deleted successfully.",
                        id: toastId,
                    });
                    this.$nextTick(() => {
                        this.toggleToast(toastId);
                    })
                })
                .finally(() => {
                    this.modal = null;
                    this.tableUpdate = new Date();
                });
        },
        cancelCallLogNoteDeletion() {
            this.modal = null;
            this.callLogNoteIdToDelete = null;
        },
        async editCallLog(id) {
            this.callLogToEdit = null;
            this.callLogModalKey = this.generateUUID();
            await this.$nextTick();
            this.$axios.get(`/api/call_logs/${id}`)
                .then(async (response) => {
                    this.callLogToEdit = response.data;
                    await this.$nextTick();
                    this.modal = this.openModal(this.$refs.callLogModal);
                });
        },
        resetFilters() {
            if (
                this.isUserWithRoleResearchAnalyst &&
                !this.isUserWithRoleBanker &&
                !this.isUserWithRoleCorporateAccess
            ) {
                this.selectedAnalysts = [{
                    id: this.userInfo.id,
                    name: this.userInfo.name,
                }];
            } else {
                this.selectedAnalysts = [];
            }
            this.selectedType = null;
            this.selectedSource = null;
            this.selectedInvestorCompanies = [];
            this.selectedInvestorContacts = [];
            this.selectedCompanies = [];
            this.lowerDate = null;
            this.upperDate = null;
            this.dates = null;
            this.$refs.callLogTable.setSearchQuery("");
            this.companiesMissing = false;
        },
        onRowsUpdated(data) {
            this.ajaxRows = data.rows;
            setTimeout(() => this.initializeOptionButtonTooltips('rows updated'), 250);

            this.pageChanged = false;
            if (data && data.rows && data.rows.length) {
                this.preSelectedRows = JSON.parse(JSON.stringify(this.preSelectedRowIds)).map(id => {
                    return {
                        key: "cln_id",
                        value: id
                    }
                });
            }
            this.ajaxAll = data.all;

        },
        clearPreSelection() {
            this.preSelectedRows = [];
            this.preSelectedRowIds = [];
            this.selectedRows = [];
        },
        onPageChange() {
            this.pageChanged = true;
        },
        async openBulkOperationModal() {
            this.bulkModalKey = this.generateUUID();
            await this.$nextTick();
            this.modal = this.openModal(this.$refs.callLogBulkOperationModal);
        },
        deleteCallLogFromModal(id) {
            this.closeCallLogModal();
            this.doDeleteCallLog(id);
        }
    }
}
